import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import './assets/style/styles.scss'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import router from './router'


let i18n = null;

i18n = createI18n({
    legacy: false,  // set to false to use composable mode
    locale: 'en', // set locale
    //fallbackLocale: languages[0], // set fallback locale 45r 
    messages: { // set locale messages
        en: {
            //menu
            menu_item_1: 'HOW IT WORKS',
            menu_item_2: 'SOLUTIONS',
            menu_item_3: 'SHOWROOM',
            menu_item_4: 'BLOG',
            menu_item_5: 'GET IN TOUCH',
            //hero
            hero_title_above: 'Know-how',
            hero_title_below: "{'@ your fingertips'}",
            hero_subtitle: 'We redefine how your organization captures, manages, and leverages operational and maintenance know-how. It\'s no longer dispersed or inefficiently managed; it\'s now centralized and easily accessible.',
            // How it works
            how_it_works_title: 'How it works',
            how_it_works_subtitle: 'in 3 STEPS',
            how_it_works_step_1: 'CAPTURE PHYSICAL SPACE AND CREATE A DIGITAL TWIN IN A SINGLE DAY',
            how_it_works_step_2: 'ATTACH MULTIMEDIA AND SENSORY DATA TO DIGITAL TWIN IN LESS THAN 25 MINUTES',
            how_it_works_step_3: 'ACCESS THE KNOW-HOW IN OUR EASY TO USE XR & WEB APPS',
            //Benefits
            benefits_title: 'Benefits',
            benefits_subtitle_1: '80% savings in time with effective documentation of maintenance processes',
            benefits_subtitle_2: '60% faster search for relevant documentation',
            benefits_subtitle_3: '25% reduction in machine downtime due to remote assistance',
            benefits_subtitle_4: '4x faster training in a VR environment',
            //know how
            know_how_title: 'Know-how',
            know_how_text: 'Is your know-how dispersed and inefficiently managed?',
            know_how_text_2: 'We offer a digital twin based platform for creating and storing information about operational and maintenance procedures that is always at your fingertips.',
            //assistance
            assistance_title: 'Assistance',
            assistance_text: 'Tired of sending your engineers on long trips for trivial tasks?',
            assistance_text_2: ' Our interactive XR remote assist module transforms how you tackle maintenance and operational challenges by instantly connecting your field operators with global experts.',
            //guidance
            guidance_title: 'Guidance',
            guidance_text: 'Can\'t find the right instructions at the right place and time?',
            guidance_text_2: ' With our MR and AR-driven guidance system, your teams always have the right instructions at their fingertips, ensuring precision and efficiency in every task.',
            //training
            training_title: 'Training',
            training_text: 'Do you employ outdated training techniques?',
            training_text_2: 'When it comes to training, our immersive VR techniques don\'t just educate; they engage and imprint knowledge for unparalleled retention and application.',
            //testimonials
            testimonial_1_title : 'Luka Kozamernik',
            testimonial_1_subtitle : 'MBA Expert in digital transformation, Sisteh Kolektor',
            testimonial_1_text: "I usually encounter solutions with terrible user experiences. The ARGO solution is just the opposite, and I believe this is their main competitive advantage and hidden potential for expansion into the industrial market through augmented reality.",
            
            testimonial_2_title : 'Mitja Antončič',
            testimonial_2_subtitle : 'Ph.D. University of Ljubljana, Faculty of Electrical Engineering Senior Data Analyst at Diagnostics and Analytics Centre at ELES, d.o.o.',
            testimonial_2_text: "Now I can be on location without being on location.",
            
            testimonial_3_title : 'Andrej Kolmanič',
            testimonial_3_subtitle : 'Head of sales, ABB Robotics',
            testimonial_3_text: "XR technology will greatly benefit the robotics industry. ARGOxr has already taken steps in this direction.",
            
            testimonial_4_title : 'Marko Bavčer',
            testimonial_4_subtitle : 'Machine learning engineer, PS Logatec',
            testimonial_4_text: "The project has expanded our horizons regarding the possibilities of managing industrial machinery and revealed new ways of learning their operation.",
            //buttons
            cta_btn: 'Book a demo',
            watch_btn: 'Watch video',
            how_it_works_btn: 'How it works',
            //get in touch
            get_in_touch_title: 'Get in touch',
            get_in_touch_subtitle: 'or BOOK A DEMO',
            get_in_touch_description: 'If you want to know more, please contact us. We\'re happy to answer your questions and prepare a live demonstration of the ARGOxr platform.',
            get_in_touch_follow_text: 'Follow us on',
            form_name: 'Name',
            form_email: 'Email',
            form_message: 'Message',
            form_organization: 'Organization',
            form_submit: 'Send',
            //statement
            statement_text_1: 'Let\’s empower',
            statement_text_2: 'your operations with the future',
            statement_text_3: 'of <b>know-how</b> management.',
            //solutions
            solution_section: 'SOLUTION',
        },
        sl: {
          menu_item_1: 'KAKO DELUJE',
          menu_item_2: 'REŠITVE',
          menu_item_3: 'SHOWROOM',
          menu_item_4: 'BLOG',
          menu_item_5: 'PIŠITE NAM',
            //hero
            hero_title_above: 'Know-how',
            hero_title_below: "{'@ your fingertips'}",
            hero_subtitle: 'Redefiniramo, kako vaša organizacija zajema, upravlja in koristi operativno ter vzdrževalno znanje. Ni več razpršeno ali neučinkovito upravljano; zdaj je centralizirano in enostavno dostopno.',
            // How it works
            how_it_works_title: 'Kako deluje',
            how_it_works_subtitle: 'v treh korakih',
            how_it_works_step_1: 'ZAJEMITE FIZIČNI PROSTOR IN USTVARITE DIGITALNEGA DVOJČKA V ENEM DNEVU',
            how_it_works_step_2: 'PRIPNITE MULTIMEDIJSKE IN SENZORIČNE PODATKE NA DIGITALNEGA DVOJČKA V MANJ KOT 25 MINUTAH',
            how_it_works_step_3: 'DOSTOPAJTE DO ZNANJA PREKO NAŠIH PREPROSTIH ZA UPORABO XR IN SPLETNIH APLIKACIJ',
            //Benefits
            benefits_title: 'KORISTI',
            benefits_subtitle_1: 'prihranjenega časa z učinkovito dokumentacijo vzdrževalnih procesov',
            benefits_subtitle_2: 'hitrejše iskanje ustrezne dokumentacije',
            benefits_subtitle_3: 'zmanjšanje časa nedelovanja stroja z uporabo oddaljene pomoči',
            benefits_subtitle_4: 'hitrejše usposabljanje v VR okolju',
            //know how
            know_how_title: 'Znanje',
            know_how_text: 'Je vaše strokovno znanje razpršeno in neučinkovito upravljano?',
            know_how_text_2: 'Ponujamo platformo, ki temelji na digitalnem dvojčku in je namenjena ustvarjanju in shranjevanju informacij o operativnih in vzdrževalnih postopkih, ki so vam vedno na dosegu roke.',
            //assistance
            assistance_title: 'Pomoč',
            assistance_text: 'Ste naveličani pošiljati svoje inženirje na dolga potovanja za trivialne naloge?',
            assistance_text_2: 'Naš interaktivni XR modul za pomoč na daljavo spreminja način reševanja vzdrževalnih in operativnih izzivov, saj v trenutku poveže vaše terenske operaterje s strokovnjaki po vsem svetu.',
            //guidance
            guidance_title: 'Vodenje',
            guidance_text: 'Ne najdete pravih navodil na pravem mestu in ob pravem času?',
            guidance_text_2: 'Z našim sistemom za vodenje, ki temelji na MR in AR tehnologiji, imajo vaše ekipe vedno prava navodila na dosegu roke, kar zagotavlja natančnost in učinkovitost pri vsaki nalogi.',
            //training
            training_title: 'Učenje',
            training_text: 'Ali uporabljate neučinkovite tehnike usposabljanja?',
            training_text_2: 'Ko gre za usposabljanje, naše poglobljene VR tehnike ne samo izobražujejo; uporabnike angažirajo in jim odtisnejo znanje v spomin.',
            //testimonials
            testimonial_1_title : 'Luka Kozamernik',
            testimonial_1_subtitle : 'Strokovnjak za digitalno preobrazbo, Sisteh Kolektor',
            testimonial_1_text: "Običajno naletim na rešitve z groznimi uporabniškimi izkušnjami. Rešitev ARGO je ravno nasprotna in menim, da je to njihova glavna konkurenčna prednost in skriti potencial za razširitev na industrijskem trgu s pomočjo razširjene resničnosti.",
            
            testimonial_2_title : 'Mitja Antončič',
            testimonial_2_subtitle : 'Doktorat z Univerze v Ljubljani, Fakulteta za elektrotehniko, Strokovnjak za podatke v Diagnostičnem in analitičnem centru pri ELES, d.o.o.',
            testimonial_2_text: "Sedaj sem lahko na lokaciji brez da grem na lokacijo.",
            
            testimonial_3_title : 'Andrej Kolmanič',
            testimonial_3_subtitle : 'Vodja prodaje, ABB Robotics',
            testimonial_3_text: "XR tehnologija bo robotski industriji zelo koristila. ARGOxr je že naredil prve korake v tej smeri.",
            
            testimonial_4_title : 'Marko Bavčer',
            testimonial_4_subtitle : 'Inženir strojnega učenja, PS Logatec',
            testimonial_4_text: "Projekt je razširil naša obzorja o možnostih upravljanja industrijskih strojev in razkril nove načine učenja njihove uporabe.",
            
            //buttons
            cta_btn: 'Rezerviraj demo',
            watch_btn: 'Poglej video',
            how_it_works_btn: 'Kako deluje',
            //get in touch
            get_in_touch_title: 'Pišite nam',
            get_in_touch_subtitle: 'ALI REZERVIRAJTE DEMONSTRACIJO',
            get_in_touch_description: 'Če želite izvedeti več, prosimo, da nas kontaktirajte. Z veseljem bomo odgovorili na vaša vprašanja in pripravili demonstracijo platforme ARGOxr v živo.',
            get_in_touch_follow_text: 'Sledi nam',
            form_name: 'Ime',
            form_email: 'Email',
            form_message: 'Sporočilo',
            form_organization: 'Organizacija',
            form_submit: 'Pošlji',
            //statement
            statement_text_1: 'Opolnomočimo',
            statement_text_2: 'vaše delovanje s prihodnostjo',
            statement_text_3: 'upravljanja znanja.',
            //solutions
            solution_section: 'REŠITEV',
        }
    },
    fallbackWarn: false,
    missing: () => {
      //console.warn(`Missing translation for ${key}`);
      return ''; // Return empty string if key is missing
    }
  })
const app = createApp(App)
app.use(i18n);
app.use(router)
app.mount('#app')
