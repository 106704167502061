import { createRouter, createWebHistory } from 'vue-router'


const router = createRouter({
    history: createWebHistory(`${import.meta.env.VITE_APP_BASE_URL}`),
    routes: [
        {
            path: '/',
            name: 'root',
            component: () => import('@/views/LandingView.vue')
        },
    ],
})
  
export default router